import { graphql } from 'gatsby';
import * as React from 'react';
import LayoutHeaderAndFooter from '../components/layout-header-and-footer';
import { padding10 } from '../styles/class-names';
import Seo from '../components/seo';

export default function About({ data }: { data: any }) {
	const image = data.site.siteMetadata.siteUrl + '/img/photos/profiles/profile-covid.jpg';
	return (
		<LayoutHeaderAndFooter>
			<Seo title="About" description="Here you'll find out 'about' Michael J. Bennett" imagePath={image} url="https://michaeljbennett.info/about" />
			<article className={padding10}>
				<h2>About</h2>
				<p>Michael Bennett likes computers. Michael Bennett likes food.</p>
			</article>
		</LayoutHeaderAndFooter>
	);
}

export const query = graphql`
	query {
		site {
			siteMetadata {
				siteUrl
			}
		}
	}
`
